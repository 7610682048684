<template>
  <div class="home home-page">
    <v-parallax
        dark
        src="@/assets/r4dm_hero_2024.png"
        jumbotron
        height="100%"
    >
      <v-row
          class="text-center"
          cols="12"
          align="center"
          justify="center"
      >
        <v-img
            class="align-self-center"
            src="@/assets/R4DM25_Annual_Patch_2025_ol.png"
            max-width="200px"
        />
      </v-row>
      <v-row
          align="center"
          justify="center"
      >
        <v-col
            class="text-center"
            cols="12"
            justify="center"
        >
          <h1 class="display-1 font-weight-thin mb-4" style="font-weight: bold!important;">
            {{ $t('main-header') }}
          </h1>
          <h2 class="font-weight-thin mb-4" style="font-weight: bold!important;">
            {{ $t('intro-header') }}
          </h2>

        </v-col>
        <v-col
            class="text-center"
            cols="12"
            style="max-width: 80vw;"
        >
          <div style="color: white; text-decoration: none!important" v-html="$t('intro-text')"/>
          <div style="color: white; text-decoration: none!important" v-html="$t('intro-text3')"/>
        </v-col>
        <v-col
            class="text-center"
            cols="12"
            style="max-width: 80vw;"
        >
          <div v-html="$t('intro-text2')"/>
        </v-col>
      </v-row>
      <v-row
          class="text-center"
          cols="12"
          align="center"
          justify="center"
      >
        <v-btn
            @click.stop="$router.push('/signup')"
            v-if="!$store.getters.isAuthenticated"
        >
          <span>{{ $t('register') }}</span>
        </v-btn>
      </v-row>
    </v-parallax>

    <Medals></Medals>
    <v-container class="lighten-3" fluid>
      <v-row>
        <v-col
            cols="12"
            justify="center"
        >
          <results-view class="resultHeight">
          </results-view>
        </v-col>

      </v-row>
    </v-container>
        <v-container class="lighten-3 blue-background" fluid style="padding: 0; box-sizing: border-box;">
      <v-row
          align="center"
          justify="center"
      >
        <v-col
            sm="12"
            md="4"
            align="center"
            style="padding: 0; box-sizing: border-box;"
        >
          <v-img
              class="align-self-center"
              src="@/assets/suojelija_2024.png"
              max-width="400px"
          />
        </v-col>
        <v-col
            sm="12"
            md="6"
            class="text--white"
            style="padding: 0; box-sizing: border-box;"
        >
          <h2 class="font-weight-thin mb-4">
            {{ $t('guy-talking-header') }}
          </h2>
          <div v-html="$t('guy-talking-text')"/>
          <h4>{{ $t('guy-talking-name') }}</h4>
          <h4>{{ $t('guy-talking-subtitle') }}</h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="lighten-3" fluid>
      
    </v-container>
    <v-container class="lighten-3" fluid style="padding-top: 50px;">
      <v-row>
        <v-col
            class="text-center"
            cols="12"
            justify="center"
        >
          <h1 class="display-1 font-weight-thin mb-4">{{ $t('sponsers-header') }}</h1>
        </v-col>
        <v-col
            class="text-center"
            cols="12"
            sm="12"
            md="8"
            offset-md="2"
           justify="center"
        >
         <Sponsers></Sponsers>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="lighten-3" fluid style="padding-top: 50px; padding-bottom: 50px;">
      <v-row>
        <v-col
            class="text-center"
            cols="12"
            justify="center"
        >
          <h1 class="display-1 font-weight-thin mb-4">{{ $t('prizes-header') }}</h1>
        </v-col>-
        <v-col
            class="text-center"
            cols="12"
        >
          <div v-html="$t('prizes-body')"/>
        </v-col>
       <v-col
            class="text-center"
            cols="12"
            sm="12"
            md="8"
            offset-md="2"
            justify="center"
        >
          <PrizeCarousel></PrizeCarousel>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row align="start" justify="start">
        <img src="@/assets/glyph-logo_May2016.png" style="width: 60px;">
        <v-col>
          <h2 class="display-1 font-weight-thin mb-4">{{ $t('ig-header') }}</h2>
        </v-col>
      </v-row>
        <iframe src='https://widgets.sociablekit.com/instagram-hashtag-feed/iframe/76780' frameborder='0' width='100%' height='700'></iframe>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
//import ResultsView from "@/views/ResultsView";
//import MerchCarousel from "@/components/MerchCarousel";
import PrizeCarousel from "@/components/PrizeCarousel";
import Sponsers from "@/components/Sponsers";
import Medals from "@/components/Medals";
import ResultsView from '@/views/ResultsView'
import Axios from "@/api/apiconfig";



export default {
  name: 'Home',
  components: {
    ResultsView,
    Medals,
    Sponsers,
    PrizeCarousel
  },

  mounted() {
    this.getAnnouncements()
  }

}

</script>

<style>
.home-page {
  color: black;
}

.v-parallax {
  height: auto !important;
  padding-top: 120px !important;
  padding-bottom: 50px;
  overflow: hidden;
  background: linear-gradient(rgb(0,0,0,0.9), rgb(0,0,0,0.9));
  background-size: cover;

}

.gray {
  background-color: #e0dfdf !important;
}

.blue-background {
  background-color: #062F6E;
}

.text--white {
  color: white;
}

.resultHeight {
  min-height: 100%;
}
</style>
